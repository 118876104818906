import React from 'react'
import { Link } from 'gatsby'

const HeaderPage = () => (
  <div>
    <h1><Link to="/">Vishal Berry</Link></h1>
    <p>Building something new in NYC</p>
  </div>
)

export default HeaderPage
