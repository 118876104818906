import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import Footer from '../components/Footer'

import HeaderPage from '../components/HeaderPage'
import WorkList from '../components/WorkList'

const WorkPage = () => (
  <div>
    <Helmet>
      <title>Work — Vishal Berry</title>
      <meta name="description" content="Work overview for Vishal Berry" />
      <html lang="en" />
    </Helmet>

    <Layout>
      <div className="content">
        <div className="inner">
          <HeaderPage />
          <WorkList />
        </div>
      </div>
      <Footer />
    </Layout>
  </div>
)

export default WorkPage
